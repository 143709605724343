<template>
  <div class="blog-more">
    <slot />
    <div class="blog-more__card-list">
      <BasePreview
        v-for="(preview, index) in previews"
        :key="index"
        class="blog-more__item"
        :preview="preview"
      />
    </div>
  </div>
</template>

<script setup>
import BasePreview from "~/modules/installationWork/components/InstallationPreview.vue";
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

const { data: previews } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type:
      useRuntimeConfig().public.contentful.contentType.installationWork,
    order: "-fields.created",
    limit: 3,
  },
});
</script>

<style lang="scss" scoped>
.blog-more {
  @extend %width-main;

  @include flex-container(column, flex-start, center);
  gap: 16px;

  &__card-list {
    width: 100%;

    @include flex-container(row, center);
    flex-wrap: wrap;

    gap: 16px;
  }

  &__item {
    --gap: 16px;

    @include flex-container(column, flex-start);

    @include set-item-count-in-row(3);

    @include bigMobile {
      @include set-item-count-in-row(2);
    }

    @include mobile {
      @include set-item-count-in-row(1);
    }
  }
}
</style>
